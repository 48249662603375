import React from 'react';
import { Typography, Box } from '@mui/material';

const Section = ({ title, children }) => {
	return (
		<Box sx={{ mb: 4 }}>
			<Typography variant="h4" component="h2" gutterBottom>
				{title}
			</Typography>
			<Typography variant="body1" component="div" sx={{ textAlign: 'justify' }}>
				{children}
			</Typography>
		</Box>
	);
};

export default Section;
