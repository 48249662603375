import React from 'react';
import { Container, Typography, Button, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { containerVariants, imageVariants, textVariants } from '../constants/motionVariants';
import PageTitle from '../components/PageTitle';

const NotFound = () => {
	const navigate = useNavigate();
	const storageBaseUrl = process.env.REACT_APP_STORAGE_BASE_URL;
	const imageUrl = `${storageBaseUrl}/images/shared/404.svg`;

	return (
		<Container
			component={motion.div}
			variants={containerVariants}
			initial="hidden"
			animate="visible"
			sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh' }}
		>
			<PageTitle title="GOFINSIGHTS" />
			<Grid container spacing={2} alignItems="center" justifyContent="center">
				<Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
					<Box
						component={motion.img}
						src={imageUrl}
						alt="404 Not Found"
						sx={{ maxWidth: '80%', height: 'auto' }}
						variants={imageVariants}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography
						variant="h4"
						gutterBottom
						component={motion.div}
						variants={textVariants}
					>
						404 - Страница не найдена
					</Typography>
					<Typography
						variant="body1"
						gutterBottom
						component={motion.div}
						variants={textVariants}
					>
						К сожалению, страница, которую вы ищете, не существует.
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => navigate('/')}
						sx={{ mt: 2 }}
						component={motion.div}
					>
						Вернуться на главную
					</Button>
				</Grid>
			</Grid>
		</Container>
	);
};

export default NotFound;
