import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { tileVariants } from '../constants/motionVariants';

const storageBaseUrl = process.env.REACT_APP_STORAGE_BASE_URL;

export const renderCollageHome = (patterns, sectionImage) => (
	<>
		<Box
			component={motion.img}
			src={`${storageBaseUrl}/images/home/${sectionImage}`}
			alt={sectionImage.replace('.svg', '')}
			variants={tileVariants}
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true }}
			sx={{
				width: '100%',
				height: 'auto',
			}}
		/>
		<Grid container spacing={1}>
			{patterns.map((pattern, index) => (
				<Grid item xs={3} key={index}>
					<Box
						component={Link}
						to={pattern.url}
						sx={{
							display: 'block',
							textDecoration: 'none',
							color: 'inherit',
						}}
					>
						<Box
							component={motion.img}
							src={`${storageBaseUrl}/images/patterns/collage/${pattern.image}`}
							alt={pattern.name}
							variants={tileVariants}
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							className="pattern-image"
							sx={{
								width: '100%',
								height: 'auto',
							}}
						/>
					</Box>
				</Grid>
			))}
		</Grid>
	</>
);
