// src/pages/PrivacyPolicy.js
import React from 'react';
import { Container, Typography } from '@mui/material';
import Section from '../components/patterns/Section';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => (
	<Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
		<Helmet>
			<title>Политика конфиденциальности - GOFINSIGHTS</title>
			<meta name="description" content="Узнайте, как мы собираем, используем и защищаем ваши персональные данные. Мы стремимся обеспечить вашу конфиденциальность на нашем сайте." />
			<meta name="keywords" content="политика конфиденциальности, персональные данные, конфиденциальность, файлы cookie, защита данных, GOFINSIGHTS" />
			<meta name="author" content="GOFINSIGHTS" />
			<meta name="robots" content="index, follow" />
			<meta property="og:title" content="Политика конфиденциальности - GOFINSIGHTS" />
			<meta property="og:description" content="Узнайте, как мы собираем, используем и защищаем ваши персональные данные. Мы стремимся обеспечить вашу конфиденциальность на нашем сайте." />
			<meta property="og:url" content="https://gofinsights.com/privacy-policy" />
			<meta property="og:type" content="article" />
			<meta property="og:locale" content="ru_RU" />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<meta name="theme-color" content="#ffffff" />
		</Helmet>

		<Typography variant="h3" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
			Политика конфиденциальности
		</Typography>

		<Section title="Введение">
			Добро пожаловать на наш сайт, посвященный паттернам проектирования. Мы уважаем вашу конфиденциальность и стремимся защищать ваши персональные данные. Настоящая Политика конфиденциальности описывает, какие данные мы собираем, как мы их используем и защищаем, а также ваши права в отношении ваших данных. Используя наш сайт, вы соглашаетесь с условиями данной Политики конфиденциальности.
		</Section>

		<Section title="Контактная информация">
			Эта политика применяется к сайту <a href="https://gofinsights.com" target="_blank" rel="noopener noreferrer">https://gofinsights.com</a>. Для вопросов по поводу данной Политики конфиденциальности свяжитесь с нами по электронной почте: <a href="mailto:support@gofinsights.ru">support@gofinsights.ru</a>
		</Section>

		<Section title="Собираемая информация">
			Мы можем собирать следующие виды данных:
			<ul>
				<li>Персональные данные: e-mail, предоставляемый при подписке на новости.</li>
				<li>Технические данные: файлы cookie, используемые для улучшения работы сайта. Список используемых cookie:
					<ul>
						<li>cookieAccepted: подтверждение согласия на использование cookie.</li>
					</ul>
				</li>
			</ul>
		</Section>

		<Section title="Порядок сбора согласий">
			Мы обрабатываем персональные данные только с согласия субъекта данных. Согласие дается путем нажатия кнопки "Подписаться" при подписке на новости. Пользователь также гарантирует, что является совершеннолетним.
		</Section>

		<Section title="Цели обработки данных">
			Мы используем собранные данные для следующих целей:
			<ul>
				<li>Отправка новостных рассылок по e-mail (если вы подписаны).</li>
				<li>Сохранение авторизации пользователей и улучшение работы сайта.</li>
			</ul>
		</Section>

		<Section title="Категории субъектов данных">
			Пользователи сайта, подписавшиеся на новости.
		</Section>

		<Section title="Перечень обрабатываемых данных">
			E-mail, cookie.
		</Section>

		<Section title="Способы обработки данных">
			Обработка данных осуществляется автоматизированным способом с использованием серверов и баз данных.
		</Section>

		<Section title="Уничтожение данных">
			Персональные данные уничтожаются в течение 30 дней после достижения цели обработки или по запросу пользователя путем стирания данных с серверов оператора.
		</Section>

		<Section title="Обмен данными">
			Мы не передаем ваши персональные данные третьим лицам без вашего согласия, за исключением случаев, предусмотренных законом.
		</Section>

		<Section title="Права пользователей">
			Вы имеете право запросить доступ к вашим персональным данным, их исправление или удаление. Для этого свяжитесь с нами по электронной почте: <a href="mailto:support@gofinsights.ru">support@gofinsights.ru</a>
		</Section>

		<Section title="Безопасность данных">
			Мы принимаем разумные меры для защиты ваших данных от несанкционированного доступа, изменения, раскрытия или уничтожения.
		</Section>

		<Section title="Файлы cookie">
			Мы используем файлы cookie для улучшения работы сайта. Продолжая использовать наш сайт, вы соглашаетесь с использованием cookie. Вы можете управлять файлами cookie через настройки вашего браузера.
		</Section>

		<Section title="Изменения в политике конфиденциальности">
			Мы оставляем за собой право обновлять данную Политику конфиденциальности. Изменения вступают в силу с момента их публикации на сайте. Актуальная версия политики всегда доступна по ссылке на сайте.
		</Section>
	</Container>
);

export default PrivacyPolicy;
