export const containerVariants = {
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { duration: 0.8 } }
};

export const imageVariants = {
	hidden: { x: '-100vw' },
	visible: { x: 0, transition: { type: 'spring', stiffness: 50 } }
};

export const textVariants = {
	hidden: { y: 50, opacity: 0 },
	visible: { y: 0, opacity: 1, transition: { duration: 0.6, delay: 0.3 } }
};

export const textVariantsLearn = {
	hidden: { y: 50, opacity: 0 },
	visible: { y: 0, opacity: 1, transition: { duration: 0.4 } }
};

export const tileVariants = {
	hidden: { opacity: 0, y: 50 },
	visible: { opacity: 1, y: 0 }
};

export const sectionVariants = {
	hidden: { opacity: 0, y: 50 },
	visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
};
