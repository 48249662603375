import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Box, Tabs, Tab, Typography, Button } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CodeBlock = ({ languages, description }) => {
	const [selectedLang, setSelectedLang] = useState(0);
	const [buttonText, setButtonText] = useState('Копировать код');

	const handleChange = (event, newValue) => {
		setSelectedLang(newValue);
		setButtonText('Копировать код');
	};

	const getLanguageAlias = (name) => {
		const aliases = {
			Java: 'java',
			Python: 'python',
			'C#': 'csharp',
			'C++': 'cpp',
			PHP: 'php',
			Go: 'go'
		};
		return aliases[name] || name.toLowerCase();
	};

	const handleCopy = () => {
		setButtonText('Скопировано!');
		setTimeout(() => setButtonText('Копировать код'), 2000);
	};

	return (
		<Box sx={{ mt: 4 }}>
			<Typography variant="h4" component="h3" gutterBottom>
				Как реализовать паттерн на примере кода?
			</Typography>
			{description && (
				<Typography variant="body1" sx={{ textAlign: 'justify', mb: 2 }}>
					{description}
				</Typography>
			)}
			<Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
				<Tabs
					value={selectedLang}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
					sx={{ flex: 1 }}
				>
					{languages.map((lang, index) => (
						<Tab key={index} label={lang.name} sx={{ fontWeight: 'bold', color: '#abafba' }} />
					))}
				</Tabs>
				<CopyToClipboard text={languages[selectedLang].code} onCopy={handleCopy}>
					<Button
						sx={{
							ml: 2,
							textTransform: 'none',
							color: 'primary.main'
						}}
						variant="outlined"
					>
						{buttonText}
					</Button>
				</CopyToClipboard>
			</Box>
			{languages.map((lang, index) => (
				<Box key={index} sx={{ display: selectedLang === index ? 'block' : 'none', mt: 2 }}>
					<SyntaxHighlighter
						language={getLanguageAlias(lang.name)}
						style={okaidia}
						showLineNumbers
					>
						{lang.code}
					</SyntaxHighlighter>
				</Box>
			))}
		</Box>
	);
};

export default CodeBlock;