// src/pages/TermsOfService.js
import React from 'react';
import { Container, Typography } from '@mui/material';
import Section from '../components/patterns/Section';
import { Helmet } from 'react-helmet';

const TermsOfService = () => (
	<Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
		<Helmet>
			<title>Условия использования - GOFINSIGHTS</title>
			<meta name="description" content="Пожалуйста, внимательно прочитайте условия использования нашего сайта, посвященного паттернам проектирования. Использование сайта означает ваше согласие с нашими условиями." />
			<meta name="keywords" content="условия использования, правила сайта, паттерны проектирования, GOFINSIGHTS" />
			<meta name="author" content="GOFINSIGHTS" />
			<meta name="robots" content="index, follow" />
			<meta property="og:title" content="Условия использования - GOFINSIGHTS" />
			<meta property="og:description" content="Пожалуйста, внимательно прочитайте условия использования нашего сайта, посвященного паттернам проектирования. Использование сайта означает ваше согласие с нашими условиями." />
			<meta property="og:url" content="https://gofinsights.com/terms-of-service" />
			<meta property="og:type" content="article" />
			<meta property="og:locale" content="ru_RU" />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<meta name="theme-color" content="#ffffff" />
		</Helmet>
		<Typography variant="h3" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
			Условия использования
		</Typography>

		<Section title="1. Введение">
			Добро пожаловать на сайт, посвященный паттернам проектирования (далее "Сайт"). Пожалуйста, внимательно прочитайте данные условия использования перед началом работы с Сайтом. Использование Сайта означает ваше согласие с настоящими Условиями использования.
		</Section>

		<Section title="2. Определения">
			<Typography variant="body1">
				Пользователь: любое лицо, использующее Сайт.<br />
				Сайт: веб-ресурс, на котором размещена информация о паттернах проектирования.<br />
				Контент: вся информация, размещенная на Сайте, включая тексты, изображения, видео и другие материалы.
			</Typography>
		</Section>

		<Section title="3. Использование Сайта">
			<Typography variant="body1">
				Пользователь обязуется использовать Сайт только в законных целях и не предпринимать действий, которые могут причинить вред Сайту или его пользователям. Запрещается:
				<ul>
					<li>Размещать на Сайте материалы, нарушающие права третьих лиц.</li>
					<li>Использовать Сайт для распространения спама, рекламы или вредоносного программного обеспечения.</li>
					<li>Пытаться получить несанкционированный доступ к системам Сайта или аккаунтам других пользователей.</li>
				</ul>
			</Typography>
		</Section>

		<Section title="4. Регистрация и аккаунт">
			<Typography variant="body1">
				Для доступа к некоторым функциям Сайта может потребоваться регистрация. Пользователь обязуется предоставлять достоверную информацию при регистрации и поддерживать актуальность своих данных.
				<ul>
					<li><strong>Конфиденциальность:</strong> Пользователь несет ответственность за сохранение конфиденциальности своих учетных данных и за все действия, совершаемые под его учетной записью.</li>
					<li><strong>Ограничение ответственности:</strong> Сайт не несет ответственности за ущерб, возникший в результате несанкционированного использования учетной записи Пользователя.</li>
				</ul>
			</Typography>
		</Section>

		<Section title="5. Интеллектуальная собственность">
			<Typography variant="body1">
				Все права на Контент, размещенный на Сайте, принадлежат владельцам Сайта или другим правообладателям. Пользователь имеет право использовать Контент только для личного, некоммерческого использования. Запрещается:
				<ul>
					<li>Копировать, изменять, распространять или использовать Контент без письменного разрешения правообладателей.</li>
					<li>Удалять или изменять информацию о правах на интеллектуальную собственность, указанную в Контенте.</li>
				</ul>
			</Typography>
		</Section>

		<Section title="6. Ссылки на сторонние ресурсы">
			<Typography variant="body1">
				Сайт может содержать ссылки на сторонние веб-ресурсы. Мы не несем ответственности за содержание и политику конфиденциальности этих ресурсов. Пользователь должен самостоятельно ознакомиться с условиями использования и политикой конфиденциальности сторонних сайтов.
			</Typography>
		</Section>

		<Section title="7. Ограничение ответственности">
			<Typography variant="body1">
				Сайт предоставляется "как есть" без каких-либо гарантий. Мы не несем ответственности за:
				<ul>
					<li>Точность, полноту и актуальность информации, размещенной на Сайте.</li>
					<li>Прерывания в работе Сайта или ошибки.</li>
					<li>Ущерб, причиненный в результате использования или невозможности использования Сайта.</li>
				</ul>
			</Typography>
		</Section>

		<Section title="8. Изменения условий использования">
			<Typography variant="body1">
				Мы оставляем за собой право вносить изменения в настоящие Условия использования в любое время. Изменения вступают в силу с момента их публикации на Сайте. Пользователь обязан регулярно проверять Условия использования на предмет изменений.
			</Typography>
		</Section>

		<Section title="9. Прекращение доступа">
			<Typography variant="body1">
				Мы имеем право прекратить или приостановить доступ Пользователя к Сайту в случае нарушения настоящих Условий использования или иных правовых норм.
			</Typography>
		</Section>

		<Section title="10. Контактная информация">
			<Typography variant="body1">
				Если у вас есть вопросы или предложения по поводу настоящих Условий использования, пожалуйста, свяжитесь с нами по электронной почте: <a href="mailto:support@gofinsights.ru">support@gofinsights.ru</a>
			</Typography>
		</Section>
	</Container>
);

export default TermsOfService;