import React, { useState } from 'react';
import { Container, Typography, Box, Tabs, Tab, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { patternsCreational, patternsStructural, patternsBehavioral } from '../constants/patterns';
import { renderCollage } from '../components/patterns/renderCollage';
import { textVariants } from '../constants/motionVariants';
import TrainerSection from '../components/trainer/TrainerSection';
import { Helmet } from 'react-helmet';

const Patterns = () => {
	const [activeTab, setActiveTab] = useState(0);

	const handleChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const renderContent = () => {
		switch (activeTab) {
			case 0:
				return (
					<Box
						component={motion.div}
						variants={textVariants}
						initial="hidden"
						animate="visible"
					>
						<Typography variant="body1" component="p" sx={{ textAlign: 'justify', mb: 4 }}>
							Порождающие паттерны сосредоточены на способах создания объектов. Они абстрагируют процесс инстанцирования объектов, позволяя системе быть более независимой от конкретных классов, используемых для инстанцирования. Порождающие паттерны важны для улучшения гибкости и повторного использования кода.
						</Typography>
						{renderCollage(patternsCreational)}
					</Box>
				);
			case 1:
				return (
					<Box
						component={motion.div}
						variants={textVariants}
						initial="hidden"
						animate="visible"
					>
						<Typography variant="body1" component="p" sx={{ textAlign: 'justify', mb: 4 }}>
							Структурные паттерны описывают, как классы и объекты могут быть составлены в более крупные структуры. Они помогают создавать системы с высокой степенью гибкости и масштабируемости, упрощая управление сложностью и улучшая архитектуру приложения.
						</Typography>
						{renderCollage(patternsStructural)}
					</Box>
				);
			case 2:
				return (
					<Box
						component={motion.div}
						variants={textVariants}
						initial="hidden"
						animate="visible"
					>
						<Typography variant="body1" component="p" sx={{ textAlign: 'justify', mb: 4 }}>
							Поведенческие паттерны фокусируются на взаимодействиях между объектами и способах распределения обязанностей между ними. Эти паттерны помогают организовать алгоритмы и потоки управления внутри системы, обеспечивая эффективное и гибкое поведение приложений.
						</Typography>
						{renderCollage(patternsBehavioral)}
					</Box>
				);
			default:
				return null;
		}
	};

	return (
		<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
			<Helmet>
				<title>Паттерны (шаблоны) проектирования - GOFINSIGHTS</title>
				<meta name="description" content="Изучите основные категории паттернов проектирования: порождающие, структурные и поведенческие паттерны. Узнайте, как эти шаблоны могут улучшить ваш код и архитектуру приложения." />
				<meta name="keywords" content="паттерны проектирования, порождающие паттерны, структурные паттерны, поведенческие паттерны, шаблоны проектирования, программирование, разработка ПО, GOFINSIGHTS" />
				<meta name="author" content="GOFINSIGHTS" />
				<meta name="robots" content="index, follow" />
				<meta property="og:title" content="Паттерны (шаблоны) проектирования - GOFINSIGHTS" />
				<meta property="og:description" content="Изучите основные категории паттернов проектирования: порождающие, структурные и поведенческие паттерны. Узнайте, как эти шаблоны могут улучшить ваш код и архитектуру приложения." />
				<meta property="og:url" content="https://gofinsights.com/patterns" />
				<meta property="og:type" content="article" />
				<meta property="og:locale" content="ru_RU" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="theme-color" content="#ffffff" />
			</Helmet>

			<Typography variant="h3" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
				Паттерны проектирования
			</Typography>
			<Tabs
				value={activeTab}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				sx={{
					display: 'flex',
					justifyContent: { xs: 'flex-start', md: 'center' },
					'& .MuiTabs-flexContainer': {
						justifyContent: { xs: 'flex-start', md: 'center' }
					}
				}}
			>
				<Tab label="Порождающие" />
				<Tab label="Структурные" />
				<Tab label="Поведенческие" />
			</Tabs>
			<Box sx={{ mt: 4 }}>
				{renderContent()}
			</Box>
			<TrainerSection />
			<Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1, justifyContent: 'space-between', mt: 4 }}>
				<Button variant="outlined" component={Link} to="/" sx={{ mb: { xs: 1, md: 0 } }}>
					На главную
				</Button>
				<Button variant="contained" color="primary" component={Link} to="/learn-more-patterns">
					Узнать больше о паттернах
				</Button>
			</Box>
		</Container>
	);
};

export default Patterns;
