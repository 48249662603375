// RelatedPatterns.js
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { tileVariants } from '../../constants/motionVariants';

const storageBaseUrl = process.env.REACT_APP_STORAGE_BASE_URL;

const RelatedPatterns = ({ patterns, excludePattern }) => {
	const filteredPatterns = patterns.filter(pattern => pattern.name !== excludePattern);

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
			<Grid container spacing={2}>
				{filteredPatterns.map((pattern, index) => (
					<Grid item xs={6} sm={4} md={3} key={index}>
						<Box
							component={Link}
							to={pattern.url}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								textDecoration: 'none',
								color: 'inherit',
								minHeight: '200px', // Высота бокса для центрирования
								justifyContent: 'center'
							}}
						>
							<Box
								component={motion.img}
								src={`${storageBaseUrl}/images/patterns/collage/${pattern.image}`}
								alt={pattern.name}
								variants={tileVariants}
								initial="hidden"
								whileInView="visible"
								viewport={{ once: true }}
								className="pattern-image"
								sx={{
									width: '100%',
									height: 'auto',
									maxWidth: '150px',
									margin: '0 auto',
								}}
							/>
							<Typography variant="body2" component="p" sx={{ textAlign: 'justify', ml: 1, mr: 1 }}>
								{pattern.description}
							</Typography>
						</Box>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default RelatedPatterns;
