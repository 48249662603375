import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Link } from '@mui/material';
import Cookies from 'js-cookie';
// import './CookieBanner.css';


const CookieBanner = () => {
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const cookieAccepted = Cookies.get('cookieAccepted');
		if (!cookieAccepted) {
			setVisible(true);
		}
	}, []);

	const handleAccept = () => {
		Cookies.set('cookieAccepted', 'true', { expires: 365 });
		setVisible(false);
	};

	if (!visible) {
		return null;
	}

	return (
		<>
			<Box className="cookie-overlay visible" />
			<Box className="cookie-banner">
				<Typography variant="body1">
					Мы используем файлы cookie для улучшения работы сайта. Продолжая использовать наш сайт, вы соглашаетесь с использованием cookie и принимаете наши{' '}
					<Link href="/terms-of-service" color="primary">
						условия использования
					</Link>{' '}
					и{' '}
					<Link href="/privacy-policy" color="primary">
						политику конфиденциальности
					</Link>.
				</Typography>
				<Button variant="contained" color="primary" onClick={handleAccept}>
					Хорошо
				</Button>
			</Box>
		</>
	);
};

export default CookieBanner;
