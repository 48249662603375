// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import routes from './constants/routes';
import CookieBanner from './components/CookieBanner';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const AppContent = () => {
	const location = useLocation();
	const excludeOverlay = ['/terms-of-service', '/privacy-policy'].includes(location.pathname);

	return (
		<div className="app-content" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
			<Helmet>
				<title>Тренажер и паттерны (шаблоны) проектирования</title>
				<meta name="description" content="Изучите и применяйте паттерны проектирования, чтобы повысить свои навыки разработки программного обеспечения. Откройте для себя мир эффективного и элегантного программирования вместе с нами!" />
				<meta name="keywords" content="паттерны проектирования, шаблоны проектирования, тренажер паттернов, программирование, разработка ПО, GOFINSIGHTS, изучение паттернов, изучение шаблонов, эффективное программирование" />
				<meta name="author" content="GOFINSIGHTS" />
				<meta name="robots" content="index, follow" />
				<meta property="og:title" content="Тренажер и паттерны (шаблоны) проектирования" />
				<meta property="og:description" content="Изучите и применяйте паттерны проектирования, чтобы повысить свои навыки разработки программного обеспечения. Откройте для себя мир эффективного и элегантного программирования вместе с нами!" />
				<meta property="og:image" content="https://storage.yandexcloud.net/gofinsights.com/images/shared/logo.svg" />
				<meta property="og:url" content="https://gofinsights.com" />
				<meta property="og:type" content="website" />
				<meta property="og:locale" content="ru_RU" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="theme-color" content="#ffffff" />
			</Helmet>
			{!excludeOverlay && <CookieBanner />}
			<ScrollToTop />
			<Navbar />
			<main style={{ flex: 1 }}>
				<Routes>
					{routes.map((route, index) => (
						<Route key={index} path={route.path} element={route.element} />
					))}
				</Routes>
			</main>
			<Footer />
			<ToastContainer
				position="bottom-right" // Позиция уведомлений
				autoClose={3000} // Автоматическое закрытие через 5 секунд
				hideProgressBar={false} // Показывать индикатор прогресса
				newestOnTop={false} // Новые уведомления поверх старых
				closeOnClick // Закрывать при клике
				rtl={false} // Справа налево
				pauseOnFocusLoss />
		</div>
	);
};

const App = () => (
	<Router>
		<AppContent />
	</Router>
);

export default App;
