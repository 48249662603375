import React from 'react';
import { Box, Typography } from '@mui/material';

const NumberedList = ({ items }) => {
	return (
		<Box component="ol" sx={{ pl: 2 }}>
			{items.map((item, index) => (
				<Typography component="li" key={index} variant="body1" sx={{ mb: 1, ml: 3, mr: 3 }}>
					{item}
				</Typography>
			))}
		</Box>
	);
};

export default NumberedList;
