import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { sectionVariants } from '../../constants/motionVariants';

const TrainerSection = () => {
	return (
		<>
			<Divider sx={{ my: 4 }} />
			<Box
				component={motion.div}
				variants={sectionVariants}
				initial="hidden"
				animate="visible"
				sx={{ textAlign: 'center', mb: 4 }}
			>
				<Typography variant="h4" component="h2" gutterBottom>
					Попробуйте наш тренажер по паттернам!
				</Typography>
				<Typography variant="body1" component="p" sx={{ textAlign: 'justify', mb: 4 }}>
					Наш тренажер по паттернам проектирования, созданный на базе искусственного интеллекта, представляет собой уникальный инструмент для углубления ваших знаний и практических навыков. Этот тренажер разработан так, чтобы вы могли не просто изучать теорию, но и активно тренироваться в применении паттернов. Попробуйте тренажер бесплатно и оцените его преимущества. Наш инструмент поможет вам стать более уверенным в своих силах и овладеть важными навыками для успешной карьеры разработчика.
				</Typography>
				<Button variant="contained" color="primary" component={Link} to="/trainer" sx={{ mb: 4 }}>
					Тренажер
				</Button>
			</Box>
			<Divider sx={{ my: 4 }} />
		</>
	);
};

export default TrainerSection;
