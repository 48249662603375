import React from 'react';
import { Container, Typography, Box, Grid, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { patternsCreational, patternsStructural, patternsBehavioral } from '../constants/patterns';
import { renderCollageHome } from '../components/renderCollageHome';
import { motion } from 'framer-motion';
import { textVariants, tileVariants, sectionVariants } from '../constants/motionVariants';
import '../css/Home.css';
import { Helmet } from 'react-helmet';

const Home = () => {

	return (
		<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
			<Helmet>
				<title>Тренажер и паттерны (шаблоны) проектирования</title>
				<meta name="description" content="Изучите и применяйте паттерны проектирования, чтобы повысить свои навыки разработки программного обеспечения. Откройте для себя мир эффективного и элегантного программирования вместе с нами!" />
				<meta property="og:title" content="Тренажер и паттерны (шаблоны) проектирования" />
				<meta property="og:description" content="Изучите и применяйте паттерны проектирования, чтобы повысить свои навыки разработки программного обеспечения. Откройте для себя мир эффективного и элегантного программирования вместе с нами!" />
				<meta property="og:url" content="https://gofinsights.com" />
			</Helmet>
			<Box sx={{ position: 'relative' }}>
				<Grid container spacing={4}>
					<Grid item xs={12} md={6}>
						<Box className="sticky-text">
							<Typography
								component={motion.div}
								variants={textVariants}
								initial="hidden"
								whileInView="visible"
								viewport={{ once: true }}
								variant="h2"
								gutterBottom
							>
								Паттерны: ваш ключ к мастерству в разработке!
							</Typography>
							<Typography
								component={motion.div}
								variants={textVariants}
								initial="hidden"
								whileInView="visible"
								viewport={{ once: true }}
								variant="h5"
								gutterBottom
								sx={{ textAlign: 'justify' }}
							>
								Изучите и применяйте паттерны проектирования, чтобы повысить свои навыки разработки программного обеспечения.
								Откройте для себя мир эффективного и элегантного программирования вместе с нами!
							</Typography>
							<Box
								component={motion.div}
								variants={textVariants}
								initial="hidden"
								whileInView="visible"
								viewport={{ once: true }}
								sx={{ mt: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1 }}
							>
								<Button
									variant="contained"
									color="primary"
									sx={{ mr: { md: 2, xs: 0 }, mb: { xs: 1, md: 0 } }}
									component={Link}
									to="/patterns"
								>
									Начать
								</Button>
								<Button
									variant="outlined"
									color="primary"
									component={Link}
									to="/learn-more-patterns"
								>
									Узнать больше
								</Button>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						{renderCollageHome(patternsCreational, 'Creational.svg')}
						{renderCollageHome(patternsStructural, 'Structural.svg')}
						{renderCollageHome(patternsBehavioral, 'Behavioral.svg')}
					</Grid>
				</Grid>
			</Box>
			<Divider sx={{ my: 8 }} />
			<Grid
				component={motion.div}
				variants={sectionVariants}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				container
				spacing={4}
				alignItems="center"
				justifyContent="center"
				sx={{ mt: 8 }}
			>
				<Grid item xs={12}>
					<Box sx={{ textAlign: 'left', mb: 4 }}>
						<Grid container spacing={4} alignItems="center" justifyContent="space-between">
							<Grid item xs={12} md={6}>
								<Typography
									component={motion.div}
									variants={textVariants}
									variant="h2"
									gutterBottom
								>
									Попробуйте наш тренажер по паттернам!
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography
									component={motion.div}
									variants={textVariants}
									variant="h6"
									gutterBottom
									sx={{ textAlign: 'justify' }}
								>
									Наш тренажер по паттернам проектирования, созданный на базе искусственного интеллекта, представляет собой уникальный инструмент для углубления ваших знаний и практических навыков. Этот тренажер разработан так, чтобы вы могли не просто изучать теорию, но и активно тренироваться в применении паттернов. Попробуйте тренажер бесплатно и оцените его преимущества. Наш инструмент поможет вам стать более уверенным в своих силах и овладеть важными навыками для успешной карьеры разработчика.
								</Typography>
								<Box
									component={motion.div}
									variants={textVariants}
									sx={{ mt: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1 }}
								>
									<Button
										variant="contained"
										color="primary"
										sx={{ mr: { md: 2, xs: 0 }, mb: { xs: 1, md: 0 } }}
										component={Link}
										to="/trainer"
									>
										Тренажер
									</Button>
									<Button
										variant="outlined"
										color="primary"
										component={Link}
										to="/learn-more-trainer"
									>
										Узнать больше
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
			<Divider sx={{ my: 8 }} />
			<Grid container spacing={4} alignItems="flex-start" justifyContent="center" sx={{ mt: 8 }}>
				<Grid item xs={12} md={4}>
					<motion.div
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						transition={{ duration: 0.5, delay: 0.2 }}
						variants={tileVariants}
					>
						<Box sx={{ textAlign: 'center', mb: 4 }}>
							<Typography variant="h4" component="h2" gutterBottom>
								Исследуйте паттерны проектирования
							</Typography>
							<Typography variant="body1" component="p" gutterBottom>
								Погрузитесь в мир паттернов проектирования. Подробные описания и примеры помогут вам понять и применить эти мощные инструменты в своей работе.
							</Typography>
							<Button variant="outlined" color="primary" component={Link} to="/patterns">
								Начать
							</Button>
						</Box>
					</motion.div>
				</Grid>
				<Grid item xs={12} md={4}>
					<motion.div
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						transition={{ duration: 0.5, delay: 0.3 }}
						variants={tileVariants}
					>
						<Box sx={{ textAlign: 'center', mb: 4 }}>
							<Typography variant="h4" component="h2" gutterBottom>
								Практика с реальными задачами
							</Typography>
							<Typography variant="body1" component="p" gutterBottom>
								Практикуйтесь в использовании паттернов с нашим интерактивным тренажером на основе искусственного интеллекта. Закрепите свои знания на практике!
							</Typography>
							<Button variant="outlined" color="primary" component={Link} to="/trainer">
								Тренажер
							</Button>
						</Box>
					</motion.div>
				</Grid>
				<Grid item xs={12} md={4}>
					<motion.div
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						transition={{ duration: 0.5, delay: 0.4 }}
						variants={tileVariants}
					>
						<Box sx={{ textAlign: 'center', mb: 4 }}>
							<Typography variant="h4" component="h2" gutterBottom>
								Отслеживайте свой прогресс
							</Typography>
							<Typography variant="body1" component="p" gutterBottom>
								Отслеживайте свои успехи и достигайте новых высот. Наша система прогресса поможет вам видеть результаты и мотивировать к дальнейшему изучению.
							</Typography>
							<Button variant="outlined" color="primary" component={Link} to="/progress">
								Посмотреть
							</Button>
						</Box>
					</motion.div>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Home;
