// src/constants/routes.js
import Home from '../pages/Home';
import Patterns from '../pages/Patterns';
import UnderConstruction from '../pages/UnderConstruction';
import NotFound from '../pages/NotFound';
import LearnMorePatterns from '../pages/LearnMorePatterns';
import Singleton from '../pages/patterns/creational/Singleton';
import Builder from '../pages/patterns/creational/Builder';
import AbstractFactory from '../pages/patterns/creational/AbstractFactory';
import FactoryMethod from '../pages/patterns/creational/FactoryMethod';
import Prototype from '../pages/patterns/creational/Prototype';
import Adapter from '../pages/patterns/structural/Adapter';
import Bridge from '../pages/patterns/structural/Bridge';
import Composite from '../pages/patterns/structural/Composite';
import Decorator from '../pages/patterns/structural/Decorator';
import Facade from '../pages/patterns/structural/Facade';
import Flyweight from '../pages/patterns/structural/Flyweight';
import Proxy from '../pages/patterns/structural/Proxy';
import ChainOfResponsibility from '../pages/patterns/behavioral/ChainOfResponsibility';
import Command from '../pages/patterns/behavioral/Command';
import Interpreter from '../pages/patterns/behavioral/Interpreter';
import Iterator from '../pages/patterns/behavioral/Iterator';
import Mediator from '../pages/patterns/behavioral/Mediator';
import Memento from '../pages/patterns/behavioral/Memento';
import Observer from '../pages/patterns/behavioral/Observer';
import State from '../pages/patterns/behavioral/State';
import Strategy from '../pages/patterns/behavioral/Strategy';
import TemplateMethod from '../pages/patterns/behavioral/TemplateMethod';
import Visitor from '../pages/patterns/behavioral/Visitor';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';

const routes = [
	{ path: "/", element: <Home /> },
	{ path: "/patterns", element: <Patterns /> },
	{ path: "/trainer", element: <UnderConstruction /> },
	{ path: "/progress", element: <UnderConstruction /> },
	{ path: "/login", element: <UnderConstruction /> },
	{ path: "/learn-more-patterns", element: <LearnMorePatterns /> },
	{ path: "/learn-more-trainer", element: <UnderConstruction /> },
	{ path: "/privacy-policy", element: <PrivacyPolicy /> },
	{ path: "/terms-of-service", element: <TermsOfService /> },
	// Creational
	{ path: "/patterns/singleton", element: <Singleton /> },
	{ path: "/patterns/builder", element: <Builder /> },
	{ path: "/patterns/abstract-factory", element: <AbstractFactory /> },
	{ path: "/patterns/factory-method", element: <FactoryMethod /> },
	{ path: "/patterns/prototype", element: <Prototype /> },
	// Structural
	{ path: "/patterns/adapter", element: <Adapter /> },
	{ path: "/patterns/bridge", element: <Bridge /> },
	{ path: "/patterns/composite", element: <Composite /> },
	{ path: "/patterns/decorator", element: <Decorator /> },
	{ path: "/patterns/facade", element: <Facade /> },
	{ path: "/patterns/flyweight", element: <Flyweight /> },
	{ path: "/patterns/proxy", element: <Proxy /> },
	// Behavioral
	{ path: "/patterns/chain-of-responsibility", element: <ChainOfResponsibility /> },
	{ path: "/patterns/command", element: <Command /> },
	{ path: "/patterns/interpreter", element: <Interpreter /> },
	{ path: "/patterns/iterator", element: <Iterator /> },
	{ path: "/patterns/mediator", element: <Mediator /> },
	{ path: "/patterns/memento", element: <Memento /> },
	{ path: "/patterns/observer", element: <Observer /> },
	{ path: "/patterns/state", element: <State /> },
	{ path: "/patterns/strategy", element: <Strategy /> },
	{ path: "/patterns/template-method", element: <TemplateMethod /> },
	{ path: "/patterns/visitor", element: <Visitor /> },
	{ path: "*", element: <NotFound /> },
];

export default routes;
