import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import '../css/Navbar.css'; // Добавляем стили для Navbar

const LoginButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.primary.light,
	color: theme.palette.primary.contrastText,
	'&:hover': {
		backgroundColor: theme.palette.primary.main,
	},
}));

const Navbar = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);

	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setDrawerOpen(open);
	};

	const storageBaseUrl = process.env.REACT_APP_STORAGE_BASE_URL;
	const logoUrl = `${storageBaseUrl}/images/shared/logo.navbar.svg`;

	const list = () => (
		<Box
			sx={{ width: 250 }}
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			<List>
				<ListItemButton component={Link} to="/patterns">
					<ListItemText primary="Паттерны" />
				</ListItemButton>
				<ListItemButton component={Link} to="/trainer">
					<ListItemText primary="Тренажер" />
				</ListItemButton>
				<ListItemButton component={Link} to="/login">
					<ListItemText primary="Вход" />
				</ListItemButton>
			</List>
			<Divider />
			<List>
				<ListItemButton component={Link} to="/privacy-policy">
					<ListItemText primary="Политика конфиденциальности" />
				</ListItemButton>
				<ListItemButton component={Link} to="/terms-of-service">
					<ListItemText primary="Условия обслуживания" />
				</ListItemButton>
			</List>
		</Box>
	);

	return (
		<AppBar position="fixed" className="navbar">
			<Toolbar>
				<IconButton
					edge="start"
					color="inherit"
					aria-label="menu"
					sx={{ mr: 2, display: { xs: 'block', md: 'none' } }}
					onClick={toggleDrawer(true)}
				>
					<MenuIcon />
				</IconButton>
				<Box sx={{ flexGrow: 1 }}>
					<Link to="/" style={{ textDecoration: 'none' }}>
						<Box
							component="img"
							src={logoUrl}
							alt="GOFINSIGHTS logo"
							sx={{ height: 40, width: 'auto' }}
						/>
					</Link>
				</Box>
				<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
					<Button color="inherit" component={Link} to="/patterns">Паттерны</Button>
					<Button color="inherit" component={Link} to="/trainer">Тренажер</Button>
					<LoginButton variant="contained" component={Link} to="/login">
						Вход
					</LoginButton>
				</Box>
			</Toolbar>
			<Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
				{list()}
			</Drawer>
		</AppBar>
	);
};

export default Navbar;
