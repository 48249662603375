import React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NavButtons = () => {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
			<Button variant="outlined" component={Link} to="/patterns">
				В каталог
			</Button>
			<Button variant="contained" color="primary" component={Link} to="/">
				На главную
			</Button>
		</Box>
	);
};

export default NavButtons;
