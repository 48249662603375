export const patternsCreational = [
	{ name: 'Singleton', fullName: 'Одиночка (Singleton)', image: 'creational/Singleton.svg', url: '/patterns/singleton', description: 'Обеспечивает создание только одного экземпляра класса и предоставляет глобальную точку доступа к этому экземпляру.' },
	{ name: 'Factory Method', fullName: 'Фабричный метод (Factory Method)', image: 'creational/Factory method.svg', url: '/patterns/factory-method', description: 'Определяет интерфейс для создания объектов, но позволяет подклассам изменять тип создаваемых объектов.' },
	{ name: 'Abstract Factory', fullName: 'Абстрактная фабрика (Abstract Factory)', image: 'creational/Factory.svg', url: '/patterns/abstract-factory', description: 'Предоставляет интерфейс для создания семейств взаимосвязанных объектов без указания их конкретных классов.' },
	{ name: 'Builder', fullName: 'Строитель (Builder)', image: 'creational/Builder.svg', url: '/patterns/builder', description: 'Отделяет конструирование сложного объекта от его представления, позволяя использовать один и тот же процесс конструирования для создания различных представлений.' },
	{ name: 'Prototype', fullName: 'Прототип (Prototype)', image: 'creational/Prototype.svg', url: '/patterns/prototype', description: 'Позволяет создавать новые объекты путем копирования уже существующих экземпляров-прототипов.' },
];

export const patternsStructural = [
	{ name: 'Adapter', fullName: 'Адаптер (Adapter)', image: 'structural/Adapter.svg', url: '/patterns/adapter', description: 'Позволяет объектам с несовместимыми интерфейсами работать вместе.' },
	{ name: 'Bridge', fullName: 'Мост (Bridge)', image: 'structural/Bridge.svg', url: '/patterns/bridge', description: 'Разделяет абстракцию и ее реализацию, позволяя изменять их независимо друг от друга.' },
	{ name: 'Composite', fullName: 'Компоновщик (Composite)', image: 'structural/Composite.svg', url: '/patterns/composite', description: 'Объединяет объекты в древовидные структуры для представления иерархий типа "часть-целое".' },
	{ name: 'Decorator', fullName: 'Декоратор (Decorator)', image: 'structural/Decorator.svg', url: '/patterns/decorator', description: 'Динамически добавляет объектам новую функциональность, не изменяя их структуры.' },
	{ name: 'Facade', fullName: 'Фасад (Facade)', image: 'structural/Facade.svg', url: '/patterns/facade', description: 'Предоставляет унифицированный интерфейс к набору интерфейсов в подсистеме, упрощая ее использование.' },
	{ name: 'Flyweight', fullName: 'Легковес (Flyweight)', image: 'structural/FlyWeight.svg', url: '/patterns/flyweight', description: 'Минимизирует использование памяти, разделяя данные между схожими объектами.' },
	{ name: 'Proxy', fullName: 'Заместитель (Proxy)', image: 'structural/Proxy.svg', url: '/patterns/proxy', description: 'Предоставляет суррогат или заместителя другого объекта для контроля доступа к нему.' },
];

export const patternsBehavioral = [
	{ name: 'Chain of Responsibility', fullName: 'Цепочка обязанностей (Chain of Responsibility)', image: 'behavioral/ChainOfResponsibility.svg', url: '/patterns/chain-of-responsibility', description: 'Передает запрос последовательно по цепочке обработчиков, позволяя каждому обработчику либо обработать запрос, либо передать его дальше.' },
	{ name: 'Command', fullName: 'Команда (Command)', image: 'behavioral/Command.svg', url: '/patterns/command', description: 'Инкапсулирует запрос как объект, позволяя параметризовать клиентов с различными запросами, ставить запросы в очередь или протоколировать их выполнение.' },
	{ name: 'Interpreter', fullName: 'Интерпретатор (Interpreter)', image: 'behavioral/Interpreter.svg', url: '/patterns/interpreter', description: 'Определяет грамматику языка и интерпретирует предложения на этом языке.' },
	{ name: 'Iterator', fullName: 'Итератор (Iterator)', image: 'behavioral/Iterator.svg', url: '/patterns/iterator', description: 'Предоставляет способ последовательного доступа к элементам агрегатного объекта без раскрытия его внутреннего представления.' },
	{ name: 'Mediator', fullName: 'Посредник (Mediator)', image: 'behavioral/Mediator.svg', url: '/patterns/mediator', description: 'Обеспечивает взаимодействие множества объектов, управляя этим взаимодействием через центральный объект.' },
	{ name: 'Memento', fullName: 'Хранитель (Memento)', image: 'behavioral/Memento.svg', url: '/patterns/memento', description: 'Позволяет сохранять и восстанавливать внутреннее состояние объекта, не нарушая его инкапсуляцию.' },
	{ name: 'Observer', fullName: 'Наблюдатель (Observer)', image: 'behavioral/Observer.svg', url: '/patterns/observer', description: 'Определяет зависимость типа "один ко многим" между объектами, при которой изменение состояния одного объекта ведет к оповещению и обновлению всех зависимых объектов.' },
	{ name: 'State', fullName: 'Состояние (State)', image: 'behavioral/State.svg', url: '/patterns/state', description: 'Позволяет объекту изменять свое поведение в зависимости от его состояния, создавая впечатление изменения его класса.' },
	{ name: 'Strategy', fullName: 'Стратегия (Strategy)', image: 'behavioral/Strategy.svg', url: '/patterns/strategy', description: 'Определяет семейство алгоритмов, инкапсулирует каждый из них и делает их взаимозаменяемыми, позволяя изменять алгоритм независимо от клиентов, которые его используют.' },
	{ name: 'Template Method', fullName: 'Шаблонный метод (Template Method)', image: 'behavioral/TemplateMethod.svg', url: '/patterns/template-method', description: 'Определяет скелет алгоритма в методе, позволяя подклассам переопределять определенные шаги алгоритма без изменения его структуры.' },
	{ name: 'Visitor', fullName: 'Посетитель (Visitor)', image: 'behavioral/Visitor.svg', url: '/patterns/visitor', description: 'Представляет операцию, выполняемую над элементами объектной структуры, позволяя добавлять новые операции, не изменяя классы элементов.' },
];
