import React from 'react';
import { Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import Section from './patterns/Section';

const KeyDifferences = ({ patterns, excludePattern, type }) => {
	return (
		<Section title={`Какие ключевые отличия от других ${type} паттернов?`}>
			{patterns
				.filter(pattern => pattern.name !== excludePattern)
				.map((pattern, index) => (
					<Typography
						key={index}
						variant="body1"
						component="div"
						sx={{ textAlign: 'justify', mt: 2, ml: 3, mr: 3 }}
					>
						<MuiLink component={Link} to={pattern.url} sx={{ textDecoration: 'none', color: 'primary.main' }}>
							{pattern.fullName}
						</MuiLink>
						: {pattern.description}
					</Typography>
				))}
		</Section>
	);
};

export default KeyDifferences;